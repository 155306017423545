import { cn } from '@hapstack/common'

type LogoProps = {
  directory?: string
  variant?: 'default' | 'white'
  className?: string
}

export const Logo = ({
  variant = 'default',
  directory = '/images',
  className,
}: LogoProps) => (
  <div className={cn('w-36', className)}>
    <img
      src={`${directory}/logo${variant === 'white' ? '-white' : ''}.svg`}
      className="pointer-events-none w-full"
    />
  </div>
)
